<!-- @format -->
<script lang="ts">
  import { getStoresContext } from "~/stores";
  import { getFragmentConfigOr, valueGetters } from "~/components/field-utils";

  export let fragment: Fragment;
  export let request_store_key;
  const stores_context = getStoresContext(request_store_key);
  const { locale, T } = stores_context;
  const { getText, getHtml } = valueGetters(stores_context);

  $: title = $locale ? getText("title", fragment) : "";
  $: intro = $locale ? getHtml("intro", fragment) : "";
  $: submit = $locale ? getText("submit", fragment) : "";
  let backgroundColor = getFragmentConfigOr("coloredBackground", fragment, 'var(--color-accent-black)');
  $: t = $T;

</script>

<svelte:head>
  <link href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css" rel="stylesheet" type="text/css" />
</svelte:head>
{#if title || intro}
  <div style="--background-color:{backgroundColor};" class="content-wrapper" id="mc_embed_signup">
      <div class="main-text">{title}</div>
      <div class="sub-text">{@html intro}</div>
      <form class="validate" 
        action="//ainoatapiola.us9.list-manage.com/subscribe/post?u=618b19ee046d7ee1ebe0a9724&amp;id=4cb860dba2" 
        id="mc-embedded-subscribe-form" method="post" name="mc-embedded-subscribe-form" target="_blank">
        <div id="mc_embed_signup_scroll" class="newsletter-form">
          <div class="email-field-wrapper">
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label>{t`email-address`}</label>
            <input type="email" name="EMAIL" class="email-field" id="mce-EMAIL" value="" required />
          </div>
          <div aria-hidden="true" style="position: absolute; left: -5000px;">
            <input name="b_618b19ee046d7ee1ebe0a9724_4cb860dba2" tabindex="-1" type="text" value="" />
          </div>
          <input class="action-button" id="mc-embedded-subscribe" name="subscribe" type="submit" value={submit}/>
        </div>
      </form>
  </div>
{/if}

<style lang="scss">
  .content-wrapper {
    background-color: var(--background-color);
    text-align: center;
    padding: 56px 120px;
    color: var(--color-brand-primary);
    @media screen and (max-width: 425px) {
      padding: 48px;
    }
    .main-text {
      font-family: var(--font-main);
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 16px;
      padding: 0px;
      @media screen and (max-width: 768px) {
        font-size: 18px;
      }
    }
    .sub-text {
      font-size: 15px;
      margin-bottom: 41px;
    }
    .newsletter-form {
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 498px) {
        flex-direction: column;
      }
      .email-field-wrapper {
        @media screen and (max-width: 768px) {
            width: 100%;
          }
        label {
          position: absolute;
          margin-top: -15px;
          margin-left: 5px;
          padding: 5px 10px;
          color: var(--color-brand-primary);
          background-color: var(--background-color);
        }
        .email-field {
          width: 400px;
          height: 60px;
          background-color: transparent;
          border: 1px solid var(--color-brand-primary);
          color: var(--color-brand-primary);
          padding: 15px;
          font-size: 16px;
          @media screen and (max-width: 498px) {
            width: 100%;        
            margin-bottom: 24px;
          }
        }
      }
      .action-button {
        width: 200px;
        height: 60px;
        background-color: var(--color-brand-primary);
        border: none;
        color: var(--color-accent-white);
        font-size: 14px;
        text-align: center;
        &:hover {
          background-color: #fff;
          color: var(--brand-color-5);
          border: 1px solid var(--brand-color-5);
        }
        @media screen and (max-width: 498px) {
          width: 100%;
        }
      }
    }
  }
</style>
